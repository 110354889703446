;(function () {
    'use strict';

    // window.$ = window.jQuery = require('jquery');
    var ajaxApi = require('./widgets/ajax-api');

    function RkwAjax() {
        var self = this;

        jQuery(window).on('rkw-ajax-api-content-changed', this.onAjaxContentChanged.bind(this));
        this.parseContent(document.body);
    }


    /* Everything in here will be executed as soon as new content is added via AJAX*/
    RkwAjax.prototype.parseContent = function(root) {
        function find(selector) {
            return jQuery(root).is(selector) ? jQuery(root) : jQuery(root).find('*').filter(selector);
        }

        var self = this;

        /* AJAX functionality for new elements */
        find('.ajax, .ajax-feedback, .ajax .ajax-override, .ajax .ajax-send, .ajax .ajax-override-submit, .ajax input, .ajax textarea, .ajax select').ajaxApi();

        find('.next-page[href]').each(function() {
            var link = jQuery(this);
            var boxesContainer = link.parent().parent();
            boxesContainer.find('.box-loading').remove();

            if (link.hasClass('autoload'))
                jQuery(window).scroll(checkBottom);

            link.click(function() {
                if (!jQuery(this).hasClass('disabled')) {

                    link.removeAttr('href').addClass('disabled');
                    boxesContainer.append('<div class="box-loading"/>');
                    jQuery(window).scrollTop(jQuery(window).scrollTop() - 2);
                }
            });

            function checkBottom() {
                if (jQuery(window).scrollTop() + jQuery(window).height() >= jQuery(document).height() - 100) {
                    if (!link.hasClass('disabled')) {
                        link.click();
                    }
                }
            }
        });

        /* delete disabled more box if this is the only entry in the box list */
        find('div.box-wrapper .box.disabled:first-child').parent().remove();
        find('div .box.publication.disabled:first-child').parent().remove();

        /* functionality for search filters */
        var filters = find('input,select');
        filters.on('change', this.sendSearchForm);

    };
    

    RkwAjax.prototype.onAjaxContentChanged = function(event, content) {
        this.parseContent(content);

        /* trigger for external stuff */
        jQuery(document).trigger('rkw-ajax-api-content-loaded', content);

    };


    RkwAjax.prototype.sendSearchForm  = function(event) {
        var searchForm = $(this).closest('form.ajax');
        if (searchForm.length) {
            var searchResult = searchForm.siblings('.search-result-section').first();
            if (searchResult.find('.loading-indicator').length === 0) {
                searchResult.append('<div class="loading-indicator"/>');
            }

            /* trigger for external stuff */
            jQuery(document).trigger('rkw-ajax-api-search-form', searchForm);

            searchForm.submit();
        }
    };

    new RkwAjax();

}());
