function AjaxApi(element) {
    var self = this;
    this.element = element;
    this.elementType = element.prop('tagName');

    if (this.elementType === 'FORM' && this.element.hasClass('ajax')) {
        this.sendForm(this.element);
    } else if (this.elementType === 'A' && this.element.hasClass('ajax')) {
        this.sendLink(this.element);
    }
    if (this.element.hasClass('ajax-feedback') && this.element.hasClass('ajax')) {
        this.element.find('input, textarea, select').each(function() {
            self.sendField(jQuery(this));
        });
    } else if (this.element.hasClass('ajax-feedback')) {
        this.sendField(this.element);
    } else if ((this.element.closest('form.ajax.ajax-feedback').length > 0) && (this.element.is('input') || this.element.is('select') || this.element.is('textarea'))) {
        this.sendField(this.element);
    }
    if (this.element.hasClass('ajax-override')) {
        this.overrideForm(this.element);
    }
    if (this.element.hasClass('ajax-send')) {
        this.sendFormByLink(this.element);
    }
    if (this.element.hasClass('ajax-override-submit')) {
        this.sendNormalFormByLink(this.element);
    }
}

AjaxApi.prototype.sendForm = function(form) {
    var self = this;
    form.on('submit', function(event) {
        if (!form.hasClass('override-submit')) {
            event.preventDefault();
            var url = form.attr('action');
            var data = form.serializeArray();
            var type = form.data('page-type');

            // set special param if auto-complete is active
            if (form.find('input[type=search]').is(':focus')) {
                data.unshift({'name': 'searchautocomplete', 'value': 1});
            }
            self.ajaxRequest(url, data, type);
        }
    });
};

AjaxApi.prototype.sendLink = function(link) {
    var self = this;
    link.on('click', function(event) {
        event.preventDefault();
        var url = link.attr('href');
        var data = [];
        var type = link.data('page-type');
        if (! link.hasClass('disabled')) {
            self.ajaxRequest(url, data, type);

            if (link.hasClass('next-page')) {
                jQuery(document).trigger('next-page', link);
            }
        }
    });
};

AjaxApi.prototype.sendField = function(field) {
    if (!field.hasClass('feedback-field')) {
        field.addClass('feedback-field');

        var self = this;
        var url;
        var data;
        var type;

        if (typeof field.attr('data-feedback-url') !== 'undefined') {
            url = field.data('feedback-url');
            type = field.data('page-type');
        } else {
            url = field.closest('form.ajax').data('feedback-url');
            type = field.closest('form.ajax').data('page-type');
        }
        if (field.is(':radio') || field.is(':checkbox') || field.is('select')) {
            field.on('change', function() {
                data = field.serializeArray();
                self.ajaxRequest(url, data, type);
            });
        } else {
            field.on('blur', function() {
                data = field.serializeArray();
                self.ajaxRequest(url, data, type);
            });
        }
    }
};

AjaxApi.prototype.overrideForm = function(element) {
    var self = this;
    var data;
    var type;
    if (element.prop('tagName') === 'A') {
        element.on('click', function(event) {
            event.preventDefault();
            var form = element.closest('form.ajax');
            var url = element.attr('href');
            type = form.data('page-type');
            data = form.serializeArray();
            self.ajaxRequest(url, data, type);
        });
    } else if (element.prop('tagName') === 'SELECT') {
        element.on('change', function() {
            var select = jQuery(this);
            if (select.val()) {
                var form = select.closest('form.ajax');
                var url = select.val();
                type = form.data('page-type');
                data = form.serializeArray();
                self.ajaxRequest(url, data, type);
            }
        });
    }
};

AjaxApi.prototype.sendFormByLink = function(link) {
    var self = this;
    link.on('click', function(event) {
        event.preventDefault();
        var form = link.closest('form.ajax');
        var url = form.attr('action');
        var data = form.serializeArray();
        var type = form.data('page-type');

        self.ajaxRequest(url, data, type);
    });
};

AjaxApi.prototype.sendNormalFormByLink = function(link) {
    var self = this;
    link.on('click', function(event) {
        event.preventDefault();
        var form = link.closest('form.ajax');
        form.attr('action', link.attr('href')).addClass('override-submit');
        form.submit();
    });
};

AjaxApi.prototype.ajaxRequest = function(url, data, type) {
    var self = this;
    if (! type)
        type = 1433770902;
    data.unshift({'name': 'type', 'value': type});
    jQuery.ajax({
        'method': 'get',
        'url': url,
        'data': jQuery.param(data),
        'dataType': 'json',
        //'username': 'rkw-kompetenz',
        //'password': 'nvFHKGG6578zfasfF',
        'complete': function (response) {
            // console.log(response);
            try {
                response = JSON.parse(response.responseText);
                self.parseContent(response);
            } catch (error) {
                console.log(error.message);
            }
        }
    });
};

AjaxApi.prototype.parseContent = function(json) {
    var self = this;
    var parent;
    for (var property in json) {
        if (property === 'message') {
            var messageObject = json[property];
            for (parent in messageObject) {
                var messageContent = self.getMessageBox(messageObject[parent].message, messageObject[parent].type, parent);
                self.appendContent(parent, messageContent);
            }
        } else if (property === 'data') {
            if (this.element.prop('tagName') === 'FORM') {
                jQuery.data(this.element[0], 'dataObject', json[property]);
                this.element.trigger('ajax-data-object');
            } else if (this.element.closest('form.ajax').length) {
                jQuery.data(this.element.closest('form.ajax')[0], 'dataObject', json[property]);
                this.element.closest('form.ajax').trigger('ajax-data-object');
            }
        } else if (property === 'html') {
            var htmlObject = json[property];
            for (parent in htmlObject) {
                for (var method in htmlObject[parent]) {
                    if (method === 'append') {
                        self.appendContent(parent, htmlObject[parent][method]);
                    } else if (method === 'prepend') {
                        self.prependContent(parent, htmlObject[parent][method]);
                    } else if (method === 'replace') {
                        self.replaceContent(parent, htmlObject[parent][method]);
                    }
                }
            }
        } else if (
            (property === 'javaScriptBefore')
            || (property === 'javaScriptAfter')
        ) {
            try {
                eval(json[property]);
            } catch (error) {
                console.log(error.message);
            }
        }
    }

    jQuery(document).on('next-page', function(event, element) {
        jQuery.data(element, 'json-ajax', json);
    });
};

AjaxApi.prototype.getMessageBox = function(text, type, parent) {
    var box = jQuery('<div class="message-box" data-for="#' + parent + '">' + text + '</div>');
    if (type === 1) {
        box.addClass('success');
    } else if (type === 2) {
        box.addClass('hint');
    } else if (type === 99) {
        box.addClass('error');
    }

    return box;
};

AjaxApi.prototype.appendContent = function(element, content) {
    try {
        var newContent = jQuery(content).appendTo(jQuery('#' + element));
        jQuery('#' + element).find('.box-loading').remove();
        jQuery(document).trigger('rkw-ajax-api-content-changed', newContent.parent());
    } catch (error) {}
};

AjaxApi.prototype.prependContent = function(element, content) {
    try {
        var newContent = jQuery(content).prependTo(jQuery('#' + element));
        jQuery('#' + element).find('.box-loading').remove();
        jQuery(document).trigger('rkw-ajax-api-content-changed', newContent.parent());

    } catch (error) {}
};

AjaxApi.prototype.replaceContent = function(element, content) {
    try {
        if (jQuery(content).length > 0) {
            var newContent = jQuery(content).appendTo(jQuery('#' + element).empty());
            jQuery(document).trigger('rkw-ajax-api-content-changed', newContent);
        } else {
            jQuery('#' + element).empty().append(content);
        }
    } catch (error) {}
};

jQuery.fn.ajaxApi = function() {
    jQuery(this).each(function() {
        new AjaxApi(jQuery(this));
    });
};

module.exports = AjaxApi;
